@media (orientation: portrait) {
.orders-preparing-root {
    overflow: hidden;
    .brand-logo-container {
        width: 100%;
        img {
            filter: invert(100%);
            height: 3em;
        }
    }
    .orders-preparing-orders-container {
        display: flex;
        width: 100%;
        .order-preparing-root {
            .order-inner-container {
                background: var(--text-color-dark);
                color: var(--bg-color-dark);
                border-radius: 0.5em;
                .order-number {
                    text-align: center;
                    font-weight: bold;
                }
                .order-icons {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    .svg-container {
                        svg {
                            .svgText {
                                fill: var(--system-color3);
                            }
                        }
                    }
                }
            }
        }
    }
    .orders-preparing-orders-container.layout-0 {
        justify-content: center;
        .order-preparing-root {
            width: 50%;
        .order-inner-container {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            .order-number {
                font-size: 23vw;
                padding: 5vw 0 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
            .order-icons {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding-bottom: 3vw;
                .svg-container {
                    width: 8vw;
                    height: 8vw;
                }
            }
        }
    }
    }
    .orders-preparing-orders-container.layout-2 {
        .order-preparing-root {
            width: 50%;
            padding: 0.5em 2em 2em 1em;
            &:first-child {
                padding: 0.5em 1em 2em 2em;
            }
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-number {
                    font-size: 15vw;
                    padding: 5vw 0 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 3vw;
                    .svg-container {
                        width: 8vw;
                        height: 8vw;
                    }
                }
            }
        }
    }
    .orders-preparing-orders-container.layout-4 {
        display: grid;
        gap: 1em;
        grid-template-columns: 1fr 1fr;
        padding: 0.5em 5em;
        .order-preparing-root {
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-number {
                    font-size: 10vw;
                    padding: 3vw 0 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 3vw;
                    .svg-container {
                        width: 6vw;
                        height: 6vw;
                    }
                }
            }
        }
    }
    .orders-preparing-orders-container.layout-6 {
        display: grid;
        gap: 1em;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0.5em 2em;
        .order-preparing-root {
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-number {
                    font-size: 10vw;
                    padding: 3vw 0 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 3vw;
                    .svg-container {
                        width: 6vw;
                        height: 6vw;
                    }
                }
            }
        }
    }
    .orders-preparing-orders-container.layout-9 {
        display: grid;
        gap: 0.75em;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0.5em 4.5em;
        .order-preparing-root {
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-number {
                    font-size: 8vw;
                    padding: 2vw 0 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 2vw;
                    .svg-container {
                        width: 4vw;
                        height: 4vw;
                    }
                }
            }
        }
    }
    .orders-preparing-orders-container.layout-12 {
        display: grid;
        gap: 0.75em;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 0.5em 2.5em;
        .order-preparing-root {
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-number {
                    font-size: 8vw;
                    padding: 2vw 0 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 2vw;
                    .svg-container {
                        width: 4vw;
                        height: 4vw;
                    }
                }
            }
        }
    }
}
}


@media (orientation: landscape) {
.orders-preparing-root {
    overflow: hidden;
    .brand-logo-container {
        width: 100%;
        img {
            filter: invert(100%);
            height: 3em;
        }
    }
    .orders-preparing-orders-container {
        display: flex;
        width: 100%;
        .order-preparing-root {
            .order-inner-container {
                background: var(--text-color-dark);
                color: var(--bg-color-dark);
                border-radius: 0.5em;
                .order-number {
                    text-align: center;
                    font-weight: bold;
                }
                .order-icons {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    .svg-container {
                        svg {
                            .svgText {
                                fill: var(--system-color3);
                            }
                        }
                    }
                }
            }
        }
    }
    .orders-preparing-orders-container.layout-0 {
        justify-content: center;
        .order-preparing-root {
            width: 50%;
        .order-inner-container {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            .order-number {
                font-size: 23vh;
                padding: 5vh 0 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
            .order-icons {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding-bottom: 3vh;
                .svg-container {
                    width: 8vh;
                    height: 8vh;
                }
            }
        }
    }
    }
    .orders-preparing-orders-container.layout-2 {
        .order-preparing-root {
            width: 50%;
            padding: 0.5em 2em 2em 1em;
            &:first-child {
                padding: 0.5em 1em 2em 2em;
            }
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-number {
                    font-size: 15vh;
                    padding: 5vh 0 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 3vh;
                    .svg-container {
                        width: 8vh;
                        height: 8vh;
                    }
                }
            }
        }
    }
    .orders-preparing-orders-container.layout-4 {
        display: grid;
        gap: 1em;
        grid-template-columns: 1fr 1fr;
        padding: 0.5em 5em;
        .order-preparing-root {
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-number {
                    font-size: 8vh;
                    padding: 3vh 0 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 3vh;
                    .svg-container {
                        width: 6vh;
                        height: 6vh;
                    }
                }
            }
        }
    }
    .orders-preparing-orders-container.layout-6 {
        display: grid;
        gap: 1em;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0.5em 2em;
        .order-preparing-root {
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-number {
                    font-size: 6vh;
                    padding: 3vh 0 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 3vh;
                    .svg-container {
                        width: 6vh;
                        height: 6vh;
                    }
                }
            }
        }
    }
    .orders-preparing-orders-container.layout-9 {
        display: grid;
        gap: 0.75em;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0.5em 4.5em;
        .order-preparing-root {
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-number {
                    font-size: 6vh;
                    padding: 2vh 0 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 2vh;
                    .svg-container {
                        width: 4vh;
                        height: 4vh;
                    }
                }
            }
        }
    }
    .orders-preparing-orders-container.layout-12 {
        display: grid;
        gap: 0.75em;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 0.5em 2.5em;
        .order-preparing-root {
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-number {
                    font-size: 8vh;
                    padding: 2vh 0 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 2vh;
                    .svg-container {
                        width: 4vh;
                        height: 4vh;
                    }
                }
            }
        }
    }
}
}