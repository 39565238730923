:root {
  --text-color: #000;
  --bg-color: #fff;

  --text-color-dark: #fff;
  --bg-color-dark: #000;

  --system-color1: #fff;
  --system-color2: #000;
  --system-color3: #CACACA;
  --system-color4: #777777;

  --success-color: #068650;
}
