html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body {
  margin: 0;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 48px;
  font-family: 'Roboto', sans-serif;
}
.text-center {
  text-align: center;
}
.font-upper {
  text-transform: uppercase;
}
.font-bold {
  font-weight: bold;
}
.font-smallest {
  font-size: 24px;
}
.font-small {
  font-size: 36px;
}
.font-large {
  font-size: 52px;
}
.font-largest {
  font-size: 60px;
}
.color-success {
  color: var(--success-color)
}
.hidden {
  opacity: 0;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.appear-quick {
  animation: 0.3s appear cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}
.appear {
  animation: 1s appear forwards;
}
.m-05 {
  margin: 0.5em;
}
.m-1 {
  margin: 1em;
}
.m-2 {
  margin: 2em;
}
.mt-05 {
  margin-top: 0.5em;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}
.mr-05 {
  margin-right: 0.5em;
}
.mr-1 {
  margin-right: 1em;
}
.mr-2 {
  margin-right: 2em;
}
.ml-05 {
  margin-left: 0.5em;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}
.mb-05 {
  margin-bottom: 0.5em;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mx-05 {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.mx-1 {
  margin-left: 1em;
  margin-right: 1em;
}
.mx-2 {
  margin-left: 2em;
  margin-right: 2em;
}
.my-05 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.my-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.my-2 {
  margin-top: 2em;
  margin-bottom: 2em;
}
.m-05 {
  margin: 0.5em;
}
.p-1 {
  padding: 1em;
}
.p-2 {
  padding: 2em;
}
.pt-05 {
  padding-top: 0.5em;
}
.pt-1 {
  padding-top: 1em;
}
.pt-2 {
  padding-top: 2em;
}
.pr-05 {
  padding-right: 0.5em;
}
.pr-1 {
  padding-right: 1em;
}
.pr-2 {
  padding-right: 2em;
}
.pl-05 {
  padding-left: 0.5em;
}
.pl-1 {
  padding-left: 1em;
}
.pl-2 {
  padding-left: 2em;
}
.pb-05 {
  padding-bottom: 1em;
}
.pb-2 {
  padding-bottom: 2em;
}
.px-05 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.px-1 {
  padding-left: 1em;
  padding-right: 1em;
}
.px-2 {
  padding-left: 2em;
  padding-right: 2em;
}
.py-05 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}
.py-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}