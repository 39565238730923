.device-activation-screen {
  color: none;
  z-index: 9999;
  width: 300px;
  margin: auto;
  display: grid;
  grid-template-rows: 0.2fr 1fr;
  gap: 14px;

  .device-activation-input-container {
    width: 100%;  
    margin: 0;
    border-radius: 8px;
    border: 1px solid;
    color: #A0A0A0;
    .device-activation-input {
        padding: 1rem;
        font-size: 2rem;
        min-height: 50px;

    }
  }

  .device-activation-numpad {
      width: 100%;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 14px;
  }

  .device-activation-button {
    color: #A0A0A0;
    min-height: 50px;
    padding: 0.5rem;
    border-radius: 8px;
    border: 1px solid;
    font-size: 2rem;
  }
}

.device-activation-error {
  margin-top: 14px;
  background-color: red;
  border-radius: 8px;
  border: 1px solid;
  padding: 1rem;
  font-size: 2rem;
}

.device-activation-title {
  margin-bottom: 14px;
  background-color: #A0A0A0;
  border-radius: 8px;
  border: 1px solid;
  padding: 1rem;
  font-size: 2rem;
}

.device-activation-request-code {
  margin-bottom: 14px;
  background-color: #A0A0A0;
  border-radius: 8px;
  border: 1px solid;
  padding: 1rem;
  font-size: 2rem;
}

.device-activation-root {
  display: block;
}