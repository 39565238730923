#root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

@media (orientation: portrait) {
    .orders-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .orders-preparing-root {
            height: 50%;
            background: var(--bg-color-dark);
            color: var(--text-color-dark);
        }
        .orders-done-root {
            height: 50%;
            background: var(--bg-color);
            color: var(--text-color);
        }
    }
}

@media (orientation: landscape) {
    .orders-container {
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .orders-preparing-root {
            height: 100%;
            width: 50%;
            background: var(--bg-color-dark);
            color: var(--text-color-dark);
        }
        .orders-done-root {
            height: 100%;
            width: 50%;
            background: var(--bg-color);
            color: var(--text-color);
        }
    }
}