@media (orientation: portrait) {
.orders-done-root {
    .orders-done-orders-container {
        display: flex;
        width: 100%;
        .order-done-root {
            border: 1px solid var(--text-color);
            border-radius: 0.5em;
            padding: 1em;       
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-message {
                    font-size: 60px;
                }
                .order-number {
                    text-align: center;
                    font-weight: bold;
                    font-size: 270px;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    left: 0;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    .svg-container {
                        svg {
                            width: 10vw;
                            height: 10vw;
                            .svgText {
                                fill: var(--system-color3);
                            }
                        }
                    }
                }
            }
        }
    }
    .orders-done-orders-container.layout-0 {
        justify-content: center;
    }
    .orders-done-orders-container.layout-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        padding: 0 1em;
        .order-done-root {  
            padding: 0.5em;
            .order-inner-container {
                .order-message {
                    font-size: 40px;
                }
                .order-number {
                    font-size: 190px;
                }
                .order-icons {
                    .svg-container {
                        svg {
                            width: 7vw;
                            height: 7vw;
                        }
                    }
                }
            }
        }
    }
    .orders-done-orders-container.layout-4 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        padding: 0 3em;
        .order-done-root {  
            padding: 0.5em;
            .order-inner-container {
                .order-message {
                    font-size: 30px;
                }
                .order-number {
                    font-size: 150px;
                }
                .order-icons {
                    .svg-container {
                        svg {
                            width: 7vw;
                            height: 7vw;
                        }
                    }
                }
            }
        }
    }
    .orders-done-orders-container.layout-6 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1em;
        padding: 0 1em;
        .order-done-root {  
            padding: 0.5em;
            .order-inner-container {
                .order-message {
                    font-size: 22px;
                }
                .order-number {
                    font-size: 120px;
                }
                .order-icons {
                    .svg-container {
                        svg {
                            width: 5vw;
                            height: 5vw;
                        }
                    }
                }
            }
        }
    }
    .orders-done-orders-container.layout-9 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1em;
        padding: 0 3em;
        .order-done-root {  
            padding: 0.25em;
            .order-inner-container {
                .order-message {
                    font-size: 18px;
                }
                .order-number {
                    font-size: 100px;
                }
                .order-icons {
                    .svg-container {
                        svg {
                            width: 4vw;
                            height: 4vw;
                        }
                    }
                }
            }
        }
    }
    .orders-done-orders-container.layout-12 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1em;
        padding: 0 1em;
        .order-done-root {  
            padding: 0.25em;
            .order-inner-container {
                .order-message {
                    font-size: 14px;
                }
                .order-number {
                    font-size: 100px;
                }
                .order-icons {
                    .svg-container {
                        svg {
                            width: 4vw;
                            height: 4vw;
                        }
                    }
                }
            }
        }
    }
}
}



@media (orientation: landscape) {
.orders-done-root {
    .orders-done-orders-container {
        display: flex;
        width: 100%;
        .order-done-root {
            border: 1px solid var(--text-color);
            border-radius: 0.5em;
            padding: 1em;       
            .order-inner-container {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                .order-message {
                    font-size: 60px;
                }
                .order-number {
                    text-align: center;
                    font-weight: bold;
                    font-size: 270px;
                }
                .order-icons {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    left: 0;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    .svg-container {
                        svg {
                            width: 10vh;
                            height: 10vh;
                            .svgText {
                                fill: var(--system-color3);
                            }
                        }
                    }
                }
            }
        }
    }
    .orders-done-orders-container.layout-0 {
        justify-content: center;
        .order-done-root {  
            padding: 2em;
            .order-inner-container {
                .order-message {
                    font-size: 60px;
                }
                .order-number {
                    font-size: 350px;
                }
            }
        }
    }
    .orders-done-orders-container.layout-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        padding: 0 1em;
        .order-done-root {  
            padding: 0.5em;
            .order-inner-container {
                .order-message {
                    font-size: 40px;
                }
                .order-number {
                    font-size: 190px;
                }
                .order-icons {
                    .svg-container {
                        svg {
                            width: 7vh;
                            height: 7vh;
                        }
                    }
                }
            }
        }
    }
    .orders-done-orders-container.layout-4 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        padding: 0 3em;
        .order-done-root {  
            padding: 0.5em;
            .order-inner-container {
                .order-message {
                    font-size: 30px;
                }
                .order-number {
                    font-size: 100px;
                }
                .order-icons {
                    .svg-container {
                        svg {
                            width: 7vh;
                            height: 7vh;
                        }
                    }
                }
            }
        }
    }
    .orders-done-orders-container.layout-6 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1em;
        padding: 0 1em;
        .order-done-root {  
            padding: 0.5em;
            .order-inner-container {
                .order-message {
                    font-size: 22px;
                }
                .order-number {
                    font-size: 80px;
                }
                .order-icons {
                    .svg-container {
                        svg {
                            width: 5vh;
                            height: 5vh;
                        }
                    }
                }
            }
        }
    }
    .orders-done-orders-container.layout-9 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1em;
        padding: 0 3em;
        .order-done-root {  
            padding: 0.25em;
            .order-inner-container {
                .order-message {
                    font-size: 18px;
                }
                .order-number {
                    font-size: 70px;
                }
                .order-icons {
                    .svg-container {
                        svg {
                            width: 4vh;
                            height: 4vh;
                        }
                    }
                }
            }
        }
    }
    .orders-done-orders-container.layout-12 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1em;
        padding: 0 1em;
        .order-done-root {  
            padding: 0.25em;
            .order-inner-container {
                .order-message {
                    font-size: 14px;
                }
                .order-number {
                    font-size: 70px;
                }
                .order-icons {
                    .svg-container {
                        svg {
                            width: 4vh;
                            height: 4vh;
                        }
                    }
                }
            }
        }
    }
}
}